import React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import {
  DocumentJSONContentIndicatorItem,
  DocumentJSONContentListItem,
  DocumentJSONContentTextItem,
} from "../../types/cms";
import ReportSectionContent from "./ReportSectionContent";

interface ReportSectionProps {
  type: "text" | "list" | "indicator";
  section:
    | DocumentJSONContentTextItem
    | DocumentJSONContentListItem
    | DocumentJSONContentIndicatorItem;
  defaultOpen?: boolean;
}

const ReportSection: React.FC<ReportSectionProps> = ({
  type,
  section,
  defaultOpen = true,
}) => {
  let contentTemplate = <></>;
  switch (type) {
    case "text":
      contentTemplate = (
        <ReportSectionContent type="text" content={section.content as string} />
      );
      break;
    case "list":
      contentTemplate = (
        <ReportSectionContent
          type="list"
          content={section.content as string[]}
        />
      );
      break;
    case "indicator":
      contentTemplate = (
        <ReportSectionContent
          type="indicator"
          content={
            section.content as {
              negative: string[];
              positive: string[];
            }
          }
        />
      );
      break;
  }
  return (
    <Disclosure defaultOpen={defaultOpen} as="div">
      {({ open = true }) => (
        <>
          <Disclosure.Button className="flex w-full items-start justify-between text-left py-4 sm:py-5 md:py-6 px-2 sm:px-4 border-t border-t-gray-300 hover:bg-gray-50">
            <h2 className="text-lg sm:text-xl font-semibold text-gray-800">
              {section.title}
            </h2>
            <div className="flex">
              <span className="mt-1 ml-6 flex h-6 items-center">
                {open ? (
                  <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
                ) : (
                  <ChevronRightIcon className="h-6 w-6" aria-hidden="true" />
                )}
              </span>
            </div>
          </Disclosure.Button>
          <Disclosure.Panel
            as="div"
            className="pl-2 pr-8 sm:pl-4 sm:pr-12 pt-0.5 md:pt-1 pb-4 xs:pb-5 md:pb-6 lg:pb-7 xl:pb-8 leading-relaxed"
          >
            {contentTemplate}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default ReportSection;
