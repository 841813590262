import React from "react";

interface ReportSectionContentListProps {
  content: string[];
}

const ReportSectionContentList: React.FC<ReportSectionContentListProps> = ({
  content,
}) => {
  if (content?.length > 0) {
    return (
      <ul>
        {content?.map((item, index) => (
          <li key={index} className="list-inside list-disc py-1.5">
            {item}
          </li>
        ))}
      </ul>
    );
  } else {
    return <></>;
  }
};

export default ReportSectionContentList;
