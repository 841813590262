import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { DocumentAttributes } from "../../types/cms";
import { getFormattedReportMetada } from "../../utils";
import ReportSection from "./ReportSection";
import { EnvelopeIcon } from "@heroicons/react/24/outline";

interface ReportViewerProps {
  report: DocumentAttributes;
}

const ReportViewer: React.FC<ReportViewerProps> = ({ report }) => {
  const { title, reportingPeriod, jsondocument } =
    getFormattedReportMetada(report);
  return (
    <AnimatePresence>
      <motion.div
        className="flex flex-col w-full mt-3 pt-2 pb-10 px-6 xs:px-8 sm:px-11 md:px-14 lg:px-18 xl:px-20 
      border-4 bg-white border-gray-200/90 border-t-blue shadow-lg"
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          stiffness: 50,
          duration: 0.4,
        }}
      >
        <div className="my-3 pl-2 pr-8 sm:pl-4 sm:pr-12 pt-3 pb-2">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold">
            {title}
          </h1>
          <div className="mt-3 md:mt-4 xl:mt-5 text-sm text-gray-800">
            {reportingPeriod}
          </div>
          <div className="text-blue mt-2 md:mt-3 xl:mt-4">
            <a
              href={`mailto:dse@bamelevate.com`}
              className="flex gap-x-1 font-bebas-neue tracking-wide text-lg"
            >
              <div>
                <span className="sr-only">Email</span>
                <EnvelopeIcon className="w-6 h-6 mt-px" />
              </div>
              Contact
            </a>
          </div>
        </div>
        <div className="mt-1 xl:text-lg">
          {!!jsondocument?.overview?.content?.length && (
            <ReportSection type="text" section={jsondocument?.overview} />
          )}
          {!!jsondocument?.expert_network_questions?.content?.length && (
            <ReportSection
              type="list"
              section={jsondocument?.expert_network_questions}
            />
          )}
          {!!jsondocument?.transcript_questions?.content?.length && (
            <ReportSection
              type="list"
              section={jsondocument?.transcript_questions}
            />
          )}
          {!!jsondocument?.insight_cluster_thesis?.content?.length && (
            <ReportSection
              type="list"
              section={jsondocument?.insight_cluster_thesis}
            />
          )}
          {!!jsondocument?.br_insights?.content && (
            <ReportSection
              type="indicator"
              section={jsondocument?.br_insights}
            />
          )}
          {!!jsondocument?.transcript_insights?.content?.length && (
            <ReportSection
              type="text"
              section={jsondocument?.transcript_insights}
              defaultOpen={false}
            />
          )}
          {!!jsondocument?.mkt_pos_report_str?.content?.length && (
            <ReportSection
              type="text"
              section={jsondocument?.mkt_pos_report_str}
              defaultOpen={false}
            />
          )}
        </div>
        <section className="mt-6">
          <h2 className="sr-only">Disclaimer</h2>
          <div className="p-4 pr-10 text-xxs sm:text-xs tracking-tight leading-snug text-gray-600">
            This information is furnished on a confidential basis exclusively
            for the use by you. By accepting this information you agree not to
            reproduce, redistribute or disclose this material, in whole or in
            part, to any other person or entity. Balyasny Asset Management L.P.
            (“BAM”) makes no warranty or representation with respect to the
            accuracy, validity or completeness of this information. Unless
            stated otherwise, the views, opinions and commentary expressed
            herein are subject to change without prior notice or any notice
            whatsoever. This is not an offer or solicitation with respect to the
            purchase or sale of any security. This information is not intended
            to be investment advice or investment recommendations. Nor should
            this information be used for investment decision making purposes and
            is not being included to assist in these decisions. Nothing herein
            is reflecting of BAM’s judgement , position, or feeling son any
            investment or security. All information, infographics, or other info
            illustrative purposes only and cannot be used for investment
            decision making purposes and are not included to assist in such
            decisions.
          </div>
        </section>
      </motion.div>
    </AnimatePresence>
  );
};

export default ReportViewer;
