import React, { useEffect } from "react";
import useNorthstar from "../../../hooks/northstarHook";
import { useAuthContext } from "../../../contexts/authContext";
import { CMSAPIDataCollection } from "../../../types/cms";
import { getFormattedReportMetada } from "../../../utils";

import ReportViewer from "../../../components/ReportViewer";
import { Link, navigate } from "gatsby";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import LoadingStatus from "../../../components/LoadingStatus";
import useURLQueryParameter from "../../../hooks/queryParamHook";
import { InsightsDocumentAPIItem } from "../../../hooks/documentsHook";
import NorthstarPageLayout from "../../../components/NorthstarPageLayout";
import { SCOPES } from "../../../constants";

const InsightsIndividualReport: React.FC = () => {
  const { accessToken, clearIdentity } = useAuthContext();
  const [id] = useURLQueryParameter("id");

  const { data, isLoading, error } = useNorthstar<
    CMSAPIDataCollection<InsightsDocumentAPIItem>
  >(`/api/transcripts/${id}?populate[0]=associated_ticker`, accessToken, {
    method: "get",
    fields: [
      "report_id",
      "ticker",
      "company_name",
      "report_date",
      "report_start_date",
      "jsondocument",
      "logo_url",
    ],
  });

  useEffect(() => {
    let ignore = true;
    if (error?.status === 401 && !ignore && clearIdentity) {
      clearIdentity();
    }
    return () => {
      ignore = false;
    };
  }, [error?.status]);
  // Redirect to '/insights' if no report_id is provided
  useEffect(() => {
    if (!id) {
      navigate("/company_research");
    }
  }, [id]);

  let reportDetailsTemplate = (
    <LoadingStatus loadingType="report_details_loading" />
  );

  let ticker = "";

  if (error) {
    reportDetailsTemplate = (
      <LoadingStatus loadingType="report_details_error" />
    );
  } else {
    if (isLoading) {
      reportDetailsTemplate = (
        <LoadingStatus loadingType="report_details_loading" />
      );
    } else if (!data?.data) {
      reportDetailsTemplate = (
        <LoadingStatus loadingType="report_details_error" />
      );
    } else {
      const reportData = data.data.attributes;

      ticker = reportData.ticker || "";
      reportDetailsTemplate = <ReportViewer report={reportData} />;
    }
  }

  return (
    <NorthstarPageLayout title="Report" requiredScope={SCOPES.COMPANY_RESEARCH}>
      <div className="flex w-full items-center flex-col font-roboto">
        <div className="max-w-[1200px]">
          <nav
            className="flex mt-3 font-bebas-neue tracking-wide"
            aria-label="Breadcrumb"
          >
            <div className="flex items-center space-x-4">
              <Link
                to="/company_research"
                className="text-blue hover:text-blue-light"
              >
                ALL REPORTS
              </Link>
              <ChevronRightIcon
                className="h-3 w-3 flex-shrink-0"
                aria-hidden="true"
              />
              <div className="text-orange">{ticker}</div>
            </div>
          </nav>
          {reportDetailsTemplate}
        </div>
      </div>
    </NorthstarPageLayout>
  );
};

export default InsightsIndividualReport;
