import React from "react";
import ReportSectionContentList from "./ReportSectionContentList";

interface ReportSectionContentProps {
  type: "text" | "list" | "indicator";
  content: string | string[] | { negative: string[]; positive: string[] };
}

const ReportSectionContent: React.FC<ReportSectionContentProps> = ({
  type,
  content,
}) => {
  let contentTemplate = <></>;
  switch (type) {
    case "text":
      contentTemplate = <>{content as string}</>;
      break;
    case "list":
      contentTemplate = (
        <ReportSectionContentList content={content as string[]} />
      );
      break;
    case "indicator":
      const { negative, positive } = content as {
        negative: string[];
        positive: string[];
      };
      contentTemplate = (
        <div className="flex flex-col">
          {(
            content as {
              negative: string[];
              positive: string[];
            }
          )?.negative?.length > 0 && (
            <div className="my-1">
              <h3 className="py-1.5 font-semibold text-red-500">
                (-) Negative
              </h3>
              <ReportSectionContentList content={negative} />
            </div>
          )}
          {(
            content as {
              negative: string[];
              positive: string[];
            }
          )?.positive?.length > 0 && (
            <div className="my-3">
              <h3 className="py-1.5 font-semibold text-green-500">
                (+) Positive
              </h3>
              <ReportSectionContentList content={positive} />
            </div>
          )}
        </div>
      );
      break;
  }

  return (
    <div className="grow py-3 whitespace-pre-wrap text-gray-800">
      {contentTemplate}
    </div>
  );
};

export default ReportSectionContent;
